import { Box, TabPanel, TabPanelsProps } from '@chakra-ui/react';

import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  CoachingToolCard,
  CoachingToolCardMenu,
  useCoachingToolsQuery,
  CoachingToolCardMenuCopyToSprint,
} from 'features/coachingTool';
import { PlanningBoardItemsSeparator } from 'features/planning';

import { BoardColumn } from 'shared/components/Board';

const PlanningBacklogCoachingTools = () => {
  const { data: coachingTools, isLoading } = useCoachingToolsQuery();

  if (isLoading) {
    return <Loader />;
  }

  return coachingTools?.length ? (
    <>
      <Box style={{ position: 'sticky', top: '0', zIndex: 111 }}>
        <PlanningBoardItemsSeparator
          type="coaching tool"
          itemsCount={coachingTools.length}
        />
      </Box>
      <BoardColumn>
        {coachingTools.map(({ name, description, picture, id }) => (
          <Spacer size="sm" key={id}>
            <CoachingToolCard
              name={name}
              description={description}
              url={picture?.url}
              id={id}
            >
              <CoachingToolCardMenu toolId={id}>
                <CoachingToolCardMenuCopyToSprint toolId={id} />
              </CoachingToolCardMenu>
            </CoachingToolCard>
          </Spacer>
        ))}
      </BoardColumn>
    </>
  ) : null;
};

type TPlanningBacklogCoachingToolsTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogCoachingToolsTab = (
  props: TPlanningBacklogCoachingToolsTab
) => (
  <TabPanel
    {...props}
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
  >
    <PlanningBacklogCoachingTools />
  </TabPanel>
);
