import React from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  useMovePlanningChallengeMutation,
  PlanningDraftSprintChallengeCard,
  PlanningBoardDropZone,
  IPlanningDraftSprint,
  PlanningBacklogChallengeCard,
} from 'features/planning';

import { BoardColumnItem } from 'shared/components/Board';

type TPlanningBoardItems = {
  challenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  canDrop: (challengeIndex: number) => (item: any) => boolean;
  sprint?: IPlanningDraftSprint;
};

export const PlanningBoardItems = ({
  challenges,
  canDrop,
  sprint,
}: TPlanningBoardItems) => {
  const { mutateAsync } = useMovePlanningChallengeMutation();

  const sprintId = sprint?.sprintId;
  const isDroppedToBacklog = !sprintId;

  return (
    <>
      {challenges?.length ? (
        <>
          {challenges.map((challenge, challengeIndex) => {
            const previousChallengeId =
              challenges[challengeIndex - 1]?.challengeId || null;

            return (
              <React.Fragment key={`${challenge.challengeId}`}>
                <PlanningBoardDropZone
                  onDrop={mutateAsync}
                  handleCanDrop={canDrop(challengeIndex)}
                  dropZoneIndex={challengeIndex}
                  accept={['challenge']}
                  isLast={false}
                  dropZoneSprintId={sprintId}
                  isDropzoneInBacklog={isDroppedToBacklog}
                  lowerChallengeId={previousChallengeId}
                >
                  <BoardColumnItem
                    item={{
                      ...challenge,
                      index: challengeIndex,
                      fromSprintId: sprintId,
                      fromBacklog: isDroppedToBacklog,
                    }}
                    type={'challenge'}
                  >
                    <Spacer size="zr sm">
                      <ChallengeCard challenge={challenge} sprint={sprint} />
                    </Spacer>
                  </BoardColumnItem>
                </PlanningBoardDropZone>
                <Spacer size="xsm" />
              </React.Fragment>
            );
          })}
        </>
      ) : null}
      <PlanningBoardDropZone
        handleCanDrop={canDrop(challenges.length)}
        dropZoneIndex={challenges.length}
        onDrop={mutateAsync}
        accept={['challenge']}
        dropZoneSprintId={sprintId}
        isDropzoneInBacklog={isDroppedToBacklog}
        lowerChallengeId={challenges.at(-1)?.challengeId ?? null}
        isLast
      />
    </>
  );
};

const ChallengeCard = ({
  sprint,
  challenge,
}: {
  challenge: IRepeatedChallenge | INonRepeatedChallenge;
  sprint?: IPlanningDraftSprint;
}) => (
  <>
    {sprint ? (
      <PlanningDraftSprintChallengeCard challenge={challenge} sprint={sprint} />
    ) : (
      <PlanningBacklogChallengeCard challenge={challenge} />
    )}
  </>
);
