import { Box, TabPanel, TabPanelsProps } from '@chakra-ui/react';

import { ButtonGroup } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBoardItems,
  canItemBeDroppedInDropZone,
  PlanningBoardItemsSeparator,
  PlanningBacklogHeaderMenu,
  PlanningFilter,
  usePlanningBacklogFilterContext,
  PlanningBacklogFilterProvider,
  SelectedGoalsProvider,
} from 'features/planning';

import { BoardColumn } from 'shared/components/Board';

const PlanningBacklogChallenges = () => {
  const { filteredChallenges: challenges, isLoading } =
    usePlanningBacklogFilterContext();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box style={{ position: 'sticky', top: '0', zIndex: 111 }}>
        <PlanningBoardItemsSeparator
          type="challenge"
          itemsCount={challenges.length}
          navigation={
            <Flex alignItems="center" height="100%">
              <ButtonGroup spacing="0.5rem" size="sm">
                <PlanningFilter />
                <PlanningBacklogHeaderMenu />
              </ButtonGroup>
            </Flex>
          }
        />
      </Box>
      <BoardColumn>
        <PlanningBoardItems
          challenges={challenges}
          canDrop={canItemBeDroppedInDropZone('backlog')}
        />
      </BoardColumn>
    </>
  );
};

type TPlanningBacklogChallengesTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogChallengesTab = (
  props: TPlanningBacklogChallengesTab
) => (
  <TabPanel
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
    {...props}
  >
    <SelectedGoalsProvider>
      <PlanningBacklogFilterProvider>
        <PlanningBacklogChallenges />
      </PlanningBacklogFilterProvider>
    </SelectedGoalsProvider>
  </TabPanel>
);
