import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useMoveGoalMutation__NEW = () => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const { userId } = useCurrentModeUser();

  return useMutation(
    ({
      id,
      index,
      columnId,
    }: {
      id: string;
      index: number;
      columnId: string;
    }) => {
      return request({
        url: `/api/web/roadmaps/${userId}/move-goal`,
        body: {
          columnId,
          index,
          goalId: id,
        },
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goals, userId],
        });

        const previousLayout = queryClient.getQueryData([
          queryKeys.goals,
          userId,
        ]);

        return { previousLayout };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.goals, userId],
          context?.previousLayout
        );
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.goals, userId]);
      },
    }
  );
};
