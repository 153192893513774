import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { iconNames, Icon } from 'shared_DEPRECATED/components/Icon';

import { CoachingToolCardMenuOpenButton } from 'features/coachingTool';

import { Dropdown } from 'shared/components/Dropdown';

type TCoachingToolCardMenu = {
  toolId: string;
  children: ReactNode;
};

export const CoachingToolCardMenu = ({
  toolId,
  children,
}: TCoachingToolCardMenu) => (
  <Dropdown
    anchorContent={<Icon name={iconNames.ellipsisHorizontal} />}
    anchorSpacing={['sm']}
    placement="right-start"
    closeOnSelect={false}
    withChevronIcon={false}
  >
    <VStack align="stretch">
      <CoachingToolCardMenuOpenButton toolId={toolId} />
      {children}
    </VStack>
  </Dropdown>
);
