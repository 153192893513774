import { MenuItem } from 'shared';

import { useBacklogAddLibraryChallenge } from 'features/planning';

export const PlanningBacklogAddLibraryChallengeButton = () => {
  const openExploreChallengesModal = useBacklogAddLibraryChallenge();

  return (
    <MenuItem onClick={openExploreChallengesModal}>Add from library</MenuItem>
  );
};
