import { IconButton, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { createPortal } from 'react-dom';

import { MenuButton, Typography, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { IChallenge } from 'features/challenge/config/types';
import {
  useDeletePlanningBacklogChallengesMutation,
  usePlanningAnalyticsEvents,
  useOpenEditBacklogChallengeDialog,
} from 'features/planning';

type TPlanningBacklogItemMenu = {
  challenge: IChallenge;
};

export const PlanningBacklogMenu = ({
  challenge,
}: TPlanningBacklogItemMenu) => {
  const { sendPlanningChallengeRemovedEvent } = usePlanningAnalyticsEvents();

  const { openEditChallengeModal } = useOpenEditBacklogChallengeDialog({
    challenge,
  });

  const { mutateAsync: deleteChallenge } =
    useDeletePlanningBacklogChallengesMutation();

  const handleChallengeRemove = () => {
    sendPlanningChallengeRemovedEvent();

    deleteChallenge([challenge.challengeId!]);
  };

  return (
    <Menu placement="bottom-end" offset={[-10, 0]} autoSelect={false}>
      <MenuButton
        as={IconButton}
        icon={<Icon name={iconNames.ellipsisHorizontal} />}
        h={8}
        variant={VARIANTS.TERTIARY}
      />
      {createPortal(
        <MenuList p="0">
          <MenuItem onClick={openEditChallengeModal}>
            <Typography type="small">Edit</Typography>
          </MenuItem>
          <MenuItem onClick={handleChallengeRemove}>
            <Typography type="small" color="red">
              Remove
            </Typography>
          </MenuItem>
        </MenuList>,
        document.body
      )}
    </Menu>
  );
};
