import { Link } from 'react-router-dom';

import Box from 'shared_DEPRECATED/components/Box';
import { FlexItem, Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalCardTitle,
  GoalCardMenu,
  VISIBLE_DIMENSIONS_COUNT,
  GoalCardType,
  useGoalsNavigationLink,
  GoalImage,
  GoalBadge,
} from 'features/goal';
import { LifeDimensions } from 'features/lifeDimensions/components/LifeDimensions';

export const GoalCard = ({
  goalId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnId,
  columnLabel,
  dataTestid,
  completedAt,
  imageUrl,
  note,
}: GoalCardType & { dataTestid?: string }) => {
  const url = useGoalsNavigationLink();

  const cardBackgroundColor = !!completedAt
    ? 'linear-gradient(180deg, var(--bgCompPrimary) 10%, var(--bgCompMinorSuccess) 90%)'
    : 'var(--bgCompPrimary)';

  return (
    <Box
      style={{
        background: cardBackgroundColor,
        minHeight: '50px',
        width: '100%',
        borderRadius: 'var(--border-radius)',
      }}
      dataTestid={dataTestid}
    >
      <Link
        to={`${url}/${goalId}`}
        style={{ textDecoration: 'none', cursor: 'inherit' }}
      >
        <FlexItem flexGrow={1}>
          <Spacer size="md">
            <Flex
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="var(--spacing01)"
            >
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="flex-start"
              >
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <GoalCardTitle name={name} />
                  <LifeDimensions
                    dimensions={dimensions}
                    visibleDimensionsCount={VISIBLE_DIMENSIONS_COUNT}
                    maxWidth="13rem"
                  />
                </Flex>
                <GoalImage imageUrl={imageUrl} />
              </Flex>
              <Spacer size="xsm" />
              <Flex width="100%">
                <GoalBadge deadline={deadline} completedAt={completedAt} />
                <Box style={{ marginLeft: 'auto' }}>
                  <GoalCardMenu
                    goalId={goalId}
                    name={name}
                    dimensions={dimensions}
                    deadline={deadline}
                    columnId={columnId}
                    deepDive={deepDive}
                    columnLabel={columnLabel}
                    completedAt={completedAt}
                    note={note}
                  />
                </Box>
              </Flex>
            </Flex>
          </Spacer>
        </FlexItem>
      </Link>
    </Box>
  );
};
