import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal, SIZES } from 'shared/components/Modal';

const GoalAddConfirmationDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/goal/components/Button/Add/Dialog'),
    'GoalAddDialog'
  )
);

export const useOpenAddGoalModal = (title?: string) =>
  useCallback(
    ({ onSubmit, defaultValues }) => {
      modal.open({
        content: (
          <Suspense fallback={<Loader size="sm" />}>
            <GoalAddConfirmationDialogContent
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              title={title}
            />
          </Suspense>
        ),
        props: {
          size: SIZES.MD,
        },
      });
    },
    [title]
  );
