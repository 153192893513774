import { useQueryClient } from 'react-query';

import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';
import { SidebarBody, Typography, useSidebarContext } from 'shared';
import Box from 'shared_DEPRECATED/components/Box/Box';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  ChallengeNotesCard,
  ChallengeNotesCardReactions,
  ChallengeNotesCardReplies,
} from 'features/challengeNotes/components/Card';
import { ChallengeNotesProofCard } from 'features/challengeNotes/components/Card/ProofCard';
import { ChallengeNotesFilterEmptyState } from 'features/challengeNotes/components/Filter';
import { NOTE_TYPES } from 'features/challengeNotes/config';
import {
  useChallengeNotesContext,
  useChallengeNotesFilterContext,
} from 'features/challengeNotes/context';
import { useChallengeNotesQueryKey } from 'features/challengeNotes/hooks';
import { updateReactions } from 'features/challengeNotes/utils';
import { groupBySprintWeek } from 'features/sprint/utils';
import { USER_MODES } from 'features/user/config';
import { useUserMode } from 'features/user/hooks';

import { ChallengeNotesChallengeFilter } from '../ChallengeFilter';
import { ChallengeNotesEmptyState } from '../ChallengeNotesEmptyState';

const challengeNotesCardsByType = {
  [NOTE_TYPES.CHALLENGE_NOTE]: ChallengeNotesCard,
  [NOTE_TYPES.CHALLENGE_PROOF_NOTE]: ChallengeNotesProofCard,
};

export const ChallengeNotesSidebarBodyWithoutTabs = () => {
  const userMode = useUserMode();
  const { isFetching, notes } = useChallengeNotesContext();
  const { sprint, user } = useSidebarContext();
  const queryClient = useQueryClient();
  const challengeNotesQueryKey = useChallengeNotesQueryKey();
  const { notesFiltered } = useChallengeNotesFilterContext();
  const { sendNoteReactionAddedAnalyticsEvent } = useNotesAnalytics();

  const notesSortedByChallengeStatusDate = [...notes].sort((note1, note2) =>
    dateUtils.sortingDiff(note2.data.statusDate, note1.data.statusDate)
  );
  const sections = groupBySprintWeek(notesSortedByChallengeStatusDate, sprint);

  const onReactionsError = (_, __, context) => {
    queryClient.setQueryData(
      challengeNotesQueryKey,
      context.previousChallengeNotes
    );
  };

  const onReactionsMutate =
    (note) =>
    ({ emoji, type }) => {
      const previousChallengeNotes = queryClient.getQueryData(
        challengeNotesQueryKey
      );

      queryClient.setQueryData(challengeNotesQueryKey, (old) =>
        old.map((noteItem) => {
          if (noteItem.noteId === note.noteId) {
            return {
              ...noteItem,
              reactions: updateReactions({
                reactions: noteItem.reactions,
                emoji,
                type,
                userInfo: user,
              }),
            };
          }
          return noteItem;
        })
      );

      return { previousChallengeNotes };
    };

  if (isFetching) {
    return <Loader />;
  }

  if (!notesFiltered && notes.length === 0) {
    return (
      <ChallengeNotesEmptyState
        text={
          userMode === USER_MODES.dashboard
            ? 'The ability to add notes from the web will be added soon.'
            : 'Get insights from participant about the challenge or blockers.'
        }
      />
    );
  }

  return (
    <SidebarBody dataTestid="notes-sidebar-body">
      {notesFiltered && notes.length === 0 && (
        <ChallengeNotesFilterEmptyState />
      )}
      {Object.entries(sections).map(([sectionTitle, sectionNotes], index) => (
        <Box
          as="section"
          title={`${sectionTitle} challenge notes`}
          key={sectionTitle}
        >
          <Spacer size={`${index === 0 ? 'zr' : 'lg'} lg zr`}>
            <Typography as="h4" color="gray" fontWeight="semiBold" type="small">
              {sectionTitle}
            </Typography>
          </Spacer>
          {sectionNotes.map((note) => {
            const ChallengeNoteCardComponent =
              challengeNotesCardsByType[note.noteType];

            return (
              <ChallengeNoteCardComponent key={note.noteId} note={note}>
                <>
                  <ChallengeNotesCardReplies
                    noteId={note.noteId}
                    repliesCount={note.repliesCount}
                    userEmail={note.userEmail}
                  />
                  <ChallengeNotesCardReactions
                    noteStatus={note.status}
                    noteType={note.noteType}
                    noteId={note.noteId}
                    reactions={note.reactions}
                    userEmail={note.userEmail}
                    onError={onReactionsError}
                    onMutate={onReactionsMutate(note)}
                    onSuccess={sendNoteReactionAddedAnalyticsEvent}
                  />
                </>
              </ChallengeNoteCardComponent>
            );
          })}
        </Box>
      ))}
      <Spacer size="xlg zr zr" />
      <ChallengeNotesChallengeFilter />
    </SidebarBody>
  );
};
