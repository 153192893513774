import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import {
  useRemovePlanningDraftSprintChallengesMutation,
  IPlanningDraftSprint,
  usePlanningAnalyticsEvents,
} from 'features/planning';

import { Typography } from 'shared/components/Typography';

type TPlanningDraftSprintMenuRemoveButton = {
  challengeId: string;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintMenuRemoveButton = ({
  challengeId,
  sprint,
}: TPlanningDraftSprintMenuRemoveButton) => {
  const { sendPlanningChallengeRemovedEvent } = usePlanningAnalyticsEvents();

  const { mutateAsync: removeSprintChallenges } =
    useRemovePlanningDraftSprintChallengesMutation({
      sprint,
    });

  const handleChallengeRemove = () => {
    sendPlanningChallengeRemovedEvent();

    removeSprintChallenges([challengeId]);
  };

  return (
    <OptionsMenuButton onClick={handleChallengeRemove}>
      <Typography color="red">Remove</Typography>
    </OptionsMenuButton>
  );
};
