import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal, SIZES } from 'shared/components/Modal';

type TUseOpenCoachingToolDetails = {
  toolId: string;
};

export const useOpenCoachingToolDetails = ({
  toolId,
}: TUseOpenCoachingToolDetails) => {
  const openCoachingToolModal = useCallback(() => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <CoachingToolDialogContent toolId={toolId} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, [toolId]);

  return { openCoachingToolModal };
};

const CoachingToolDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/coachingTool/components/Dialog'),
    'CoachingToolDialog'
  )
);
