import { Flex } from 'shared_DEPRECATED/components/Flex';

import { PlanningTitle, PlanningAddSprintButton } from 'features/planning';

export const PlanningHeader = () => (
  <Flex width="100%" dataTestid="sprint-planning-header">
    <PlanningTitle />
    <PlanningAddSprintButton />
  </Flex>
);
