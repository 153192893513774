import { Flex } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { $isHeadingNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import {
  mergeRegister,
  $getNearestNodeOfType,
  $findMatchingParent,
} from '@lexical/utils';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { HeadingFormatter } from './components/HeadingFormatter';
import { ToolbarButtonIcon } from './components/ToolbarButtonIcon';
import { BlockType, blockTypeKeys } from './constants';
import { getSelectedNode } from './utils';

type ToolbarPluginProps = {
  children?: React.ReactNode;
  setIsLinkEditMode: Dispatch<SetStateAction<boolean>>;
  additionalActions?: React.ReactNode;
};

export const ToolbarPlugin = ({
  children,
  setIsLinkEditMode,
  additionalActions,
}: ToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [isLink, setIsLink] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type as BlockType);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (blockTypeKeys.includes(type)) {
            setBlockType(type as BlockType);
          }
        }
      }
    }
  }, [editor]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
    } else {
      setIsLinkEditMode(false);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  useEffect(() => {
    return activeEditor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [activeEditor, editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      })
    );
  }, [activeEditor, editor, $updateToolbar]);

  return (
    <Flex
      className="hos-text-editor__toolbar"
      justify="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        {children}
        <HeadingFormatter blockType={blockType} editor={activeEditor} />
        <ToolbarButtonIcon
          ariaLabel="bold"
          isActive={isBold}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
          icon={
            <Icon
              name={iconNames.textBold}
              fill={isBold ? 'var(--bgCompAccent)' : 'var(--fgPrimary)'}
            />
          }
        />
        <ToolbarButtonIcon
          ariaLabel="italic"
          isActive={isItalic}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
          icon={
            <Icon
              name={iconNames.textItalic}
              fill={isItalic ? 'var(--bgCompAccent)' : 'var(--fgPrimary)'}
            />
          }
        />
        <ToolbarButtonIcon
          ariaLabel="strikethrough"
          isActive={isStrikethrough}
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
          }
          icon={
            <Icon
              name={iconNames.strikeThrough}
              fill={
                isStrikethrough ? 'var(--bgCompAccent)' : 'var(--fgPrimary)'
              }
            />
          }
        />
        <ToolbarButtonIcon
          ariaLabel="underline"
          isActive={isUnderline}
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }
          icon={
            <Icon
              name={iconNames.underline}
              fill={isUnderline ? 'var(--bgCompAccent)' : 'var(--fgPrimary)'}
            />
          }
        />
        <ToolbarButtonIcon
          ariaLabel="numbered list"
          isActive={blockType === 'number'}
          onClick={formatNumberedList}
          icon={<Icon name={iconNames.numberedList} />}
        />
        <ToolbarButtonIcon
          ariaLabel="bullet list"
          isActive={blockType === 'bullet'}
          onClick={formatBulletList}
          icon={<Icon name={iconNames.bulletList} />}
        />
        <ToolbarButtonIcon
          ariaLabel="link"
          isActive={isLink}
          onClick={insertLink}
          icon={<Icon name={iconNames.link} />}
        />
      </Flex>
      {additionalActions}
    </Flex>
  );
};
