import { IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Typography,
  useSidebarContext,
  VARIANTS,
} from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  NOTE_FILTERS,
  NOTE_FILTERS_COMPONENT_CONFIG,
  NOTE_FILTERS_DEFAULT_VALUE,
  NOTE_STATUS,
} from 'features/challengeNotes/config';
import { useChallengeNotesFilterContext } from 'features/challengeNotes/context';
import { useFilterVisibility } from 'features/challengeNotes/hooks';

const ChallengeNotesFilterPropTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    filterReadStatuses: PropTypes.string,
    filterRepliedByEmails: PropTypes.string,
  }),
};

export const ChallengeNotesFilter = () => {
  const { filter, setFilter } = useChallengeNotesFilterContext();
  const { user } = useSidebarContext();
  const filterHidden = useFilterVisibility();
  const { sendNoteListFilterAppliedAnalyticsEvent } = useNotesAnalytics();

  const filterValueMapper = {
    [NOTE_FILTERS.FILTER_READ_STATUSES]: NOTE_STATUS.UNREAD,
    [NOTE_FILTERS.FILTER_REPLIED_BY_EMAILS]: user.email,
  };

  if (filterHidden) {
    return null;
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={IconButton}
        aria-label="Filter"
        icon={<Icon name={iconNames.filter} />}
        variant={VARIANTS.TERTIARY}
        data-testid="filter-btn"
      />
      <MenuList>
        <MenuOptionGroup
          onChange={(selectedOptions) => {
            Object.keys(NOTE_FILTERS_COMPONENT_CONFIG).forEach((filterKey) => {
              const newFilter = {
                [filterKey]: selectedOptions.includes(filterKey)
                  ? filterValueMapper[filterKey]
                  : NOTE_FILTERS_DEFAULT_VALUE[filterKey],
              };

              setFilter(newFilter);
              sendNoteListFilterAppliedAnalyticsEvent({
                ...filter,
                ...newFilter,
              });
            });
          }}
          type="checkbox"
          defaultValue={Object.entries(filter)
            .map(([key, value]) => {
              if (value === filterValueMapper[key]) {
                return key;
              }
              return null;
            })
            .filter(Boolean)}
          value={Object.entries(filter)
            .map(([key, value]) => {
              if (value === filterValueMapper[key]) {
                return key;
              }
              return null;
            })
            .filter(Boolean)}
        >
          {Object.entries(NOTE_FILTERS_COMPONENT_CONFIG).map(
            ([filterKey, config]) => {
              const { label, testid } = config;

              return (
                <MenuItemOption
                  key={filterKey}
                  value={filterKey}
                  data-testid={`notes-sidebar-filter-${testid}`}
                >
                  <Typography color="black" type="small">
                    {label}
                  </Typography>
                </MenuItemOption>
              );
            }
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

ChallengeNotesFilter.propTypes = ChallengeNotesFilterPropTypes;
