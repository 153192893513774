import { useCallback } from 'react';

import { AnalyticsEventSource, ANALYTICS_EVENTS } from './config';
import { useSendAnalytics } from './useSendAnalytics';

export const useChatAnalytics = () => {
  const sendAnalyticsEvent = useSendAnalytics();

  /**
   * Send an analytics event when the chat overlay is opened.
   * @param source - The source from which the chat overlay was opened. Chat can be opened from the participant page or the participants list.
   * @returns void
   */
  const sendChatOverlayOpenAnalyticsEvent = useCallback(
    ({ source }: { source: AnalyticsEventSource }) =>
      sendAnalyticsEvent({
        event: ANALYTICS_EVENTS.CHAT_OVERLAY_OPEN,
        data: {
          source,
        },
      }),
    [sendAnalyticsEvent]
  );

  /**
   * Send an analytics event when a chat message is sent.
   * @param includesAttachments - Whether the message includes at least one file that is not a media (image, video).
   * @param includesMedia - Whether the message includes media (image, video).
   * @param includesLink - Whether the message includes a link.
   * @param includesReply - Whether the message includes a reply.
   * @returns void
   */
  const sendChatMessageSentAnalyticsEvent = useCallback(
    ({
      includesAttachments,
      includesMedia,
      includesLink,
      includesReply,
      includesAudio,
      audioLength,
    }: {
      includesAttachments: boolean;
      includesMedia: boolean;
      includesLink: boolean;
      includesReply: boolean;
      includesAudio: boolean;
      audioLength: string;
    }) =>
      sendAnalyticsEvent({
        event: ANALYTICS_EVENTS.CHAT_MESSAGE_SENT,
        data: {
          includesAttachments,
          includesMedia,
          includesLink,
          includesReply,
          includesAudio,
          audioLength,
        },
      }),
    [sendAnalyticsEvent]
  );

  const sendChatReactionAddedAnalyticsEvent = useCallback(
    () =>
      sendAnalyticsEvent({
        event: ANALYTICS_EVENTS.CHAT_REACTION_ADDED,
      }),
    [sendAnalyticsEvent]
  );

  const sendChatMessageEditedAnalyticsEvent = useCallback(
    () =>
      sendAnalyticsEvent({
        event: ANALYTICS_EVENTS.CHAT_MESSAGE_EDITED,
      }),
    [sendAnalyticsEvent]
  );

  return {
    sendChatMessageSentAnalyticsEvent,
    sendChatOverlayOpenAnalyticsEvent,
    sendChatReactionAddedAnalyticsEvent,
    sendChatMessageEditedAnalyticsEvent,
  };
};
