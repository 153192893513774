import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { IPlanningDraftSprint } from 'features/planning/types';

export const useEditDraftSprintTitle = () => {
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ title, sprintId }: { title: string; sprintId: string }) =>
      request({
        url: `/api/draft-sprints/${sprintId}/title`,
        body: { title },
      }),
    {
      onMutate: async ({ title, sprintId }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              const updatedSprints = [...oldVal!.items];

              const sprintIndex = updatedSprints.findIndex(
                (sprint) => sprint.sprintId === sprintId
              );

              updatedSprints[sprintIndex].title = title;

              return { ...oldVal, items: updatedSprints };
            }
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (err: Error, __: any, context) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
    }
  );
};
