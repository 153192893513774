import { useState } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { IPlanningDraftSprint } from 'features/planning';

import { PlanningDraftSprintHeaderEditor } from './HeaderEditor';
import { PlanningDraftSprintHeaderViewer } from './HeaderViewer';

type TPlanningDraftSprintHeader = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintHeader = ({
  sprint,
}: TPlanningDraftSprintHeader) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Spacer size="sm md">
      <Flex dataTestid="sprint-planning-board-header">
        {!isEditing ? (
          <PlanningDraftSprintHeaderViewer
            sprint={sprint}
            onEdit={() => setIsEditing(true)}
          />
        ) : (
          <PlanningDraftSprintHeaderEditor
            sprint={sprint}
            onDone={() => setIsEditing(false)}
          />
        )}
      </Flex>
    </Spacer>
  );
};
