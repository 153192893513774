import { ReactNode } from 'react';

import { Box } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  ICoachingToolProps,
  CoachingToolCardHeader,
  CoachingToolCardTitle,
  CoachingToolCardDescription,
  CoachingToolImage,
} from 'features/coachingTool';
import { useOpenCoachingToolDetails } from 'features/coachingTool/hooks';

type TCoachingToolCard = Pick<
  ICoachingToolProps,
  'name' | 'description' | 'id'
> & {
  url?: string;
  children: ReactNode;
};

export const CoachingToolCard = ({
  name,
  description,
  url,
  id,
  children,
}: TCoachingToolCard) => {
  const { openCoachingToolModal } = useOpenCoachingToolDetails({ toolId: id });

  return (
    <Box
      style={{
        width: '100%',
        borderRadius: 'var(--border-radius)',
        position: 'relative',
        cursor: 'pointer',
      }}
      _hover={{
        bg: 'var(--bgCompSecondaryMid)',
      }}
      bg="var(--bgCompPrimary)"
      data-group
      onClick={openCoachingToolModal}
    >
      <Spacer size="md">
        <HotjarSuppression>
          <Flex
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="0.75rem"
          >
            <CoachingToolImage imageUrl={url} />
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              gap="0.125rem"
              width="100%"
            >
              <CoachingToolCardHeader />
              <CoachingToolCardTitle title={name} />
              <CoachingToolCardDescription description={description} />
            </Flex>
            <Box
              visibility="hidden"
              _groupHover={{
                visibility: 'visible',
              }}
              onClick={(evt) => evt.stopPropagation()}
            >
              {children}
            </Box>
          </Flex>
        </HotjarSuppression>
      </Spacer>
    </Box>
  );
};
