import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useGoalsTableQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { userId } = useCurrentModeUser();

  return useQuery(
    [queryKeys.goals, userId],
    () => request({ url: `/api/web/roadmaps/${userId}` }),
    {
      enabled: !!userId,
      initialData: { columns: [], deepDiveAvailable: false },
    }
  );
};
