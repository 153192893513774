export const queryKeys = Object.freeze({
  assessments: 'assessments',
  mySprints: 'my-sprints',
  sprints: 'sprints',
  draftSprints: 'draft-sprints',
  participants: 'participants',
  participant: 'participant',
  user: 'user',
  challenges: 'challenges',
  challengeGoals: 'challenge-goals',
  libraryChallenges: 'library-challenges',
  connections: 'connections',
  dimensions: 'dimensions',
  sharedSprints: 'shared-sprints',
  notifications: 'notifications',
  challengeNotes: 'challenge-notes',
  users: 'users',
  coaches: 'coaches',
  coachingTools: 'coachingTools',
  attachment: 'attachment',
  chatMessages: 'chats-messages',
  chats: 'chats',
  coachProfile: 'coach-profile',
  quickStartSprint: 'quick-start-sprint',
  quickStartSprints: 'quick-start-sprints',
  goals: 'goals',
  goal: 'goal',
  goalBreakdown: 'goal-breakdown',
  reflection: 'reflection',
  statistic: 'statistic',
  participantsSprints: 'participants-sprints',
  noteReplies: 'note-replies',
  scheduledMessages: 'scheduled-messages',
});

export const HTTP_ERROR_STATUS_CODES = {
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  INTERNAL_ERROR: '500',
  BAD_GATEWAY: '502',
  SERVICE_UNAVAILABLE: '503',
  GATEWAY_TIMEOUT: '504',
};

export const DEFAULT_RETRY_COUNT = 3;
