import Box from 'shared_DEPRECATED/components/Box/Box';

import { ChallengeNotesTabs } from 'features/challengeNotes/components/Tabs';

import { SidebarBody } from 'shared/components/Sidebar';

export const ChallengeNotesSidebarBody = () => (
  <SidebarBody dataTestid="notes-sidebar-body">
    <Box style={{ position: 'relative', height: '100%' }}>
      <ChallengeNotesTabs />
    </Box>
  </SidebarBody>
);
