import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
} from '@chakra-ui/react';

import { forwardRef } from '@chakra-ui/system';

export const MenuButton = forwardRef<ChakraMenuButtonProps, 'button'>(
  (props, ref) => (
    <ChakraMenuButton
      _focus={{
        outline: 'none',
      }}
      {...props}
      ref={ref}
    />
  )
);
