import React from 'react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
  CoachingToolCardMenuMoveToSprint,
  CoachingToolCardMenuRemoveButton,
} from 'features/coachingTool';
import {
  PlanningSprintHeader,
  PlanningSprintWrapper,
  PlanningSprintModel,
  PlanningBoardItemsSeparator,
  PlanningChallengeCard,
} from 'features/planning';
import { SPRINT_STATES } from 'features/sprint/config';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnItem,
  BoardColumnWrapper,
} from 'shared/components/Board';

export const PlanningSprint = ({ sprint }: { sprint: PlanningSprintModel }) => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

  return (
    <PlanningSprintWrapper sprintState={sprint.state}>
      <BoardColumnWrapper fraction={1}>
        <BoardColumnHeader minHeight="3.75rem">
          <PlanningSprintHeader title={sprint.title} sprint={sprint} />
        </BoardColumnHeader>
        <BoardColumn key={sprint.sprintId}>
          {sprint.challenges.length > 0 && (
            <>
              <PlanningBoardItemsSeparator
                type="challenge"
                itemsCount={sprint.challenges.length}
                sprintState={sprint.state}
              />
              {sprint.challenges.map((challenge, challengeIndex) => (
                <React.Fragment key={`${challenge.challengeId}`}>
                  <Spacer size="xsm" />
                  <BoardColumnItem
                    item={{
                      ...challenge,
                      index: challengeIndex,
                      fromSprintId: sprint.sprintId,
                      fromBacklog: false,
                    }}
                    type={'challenge'}
                  >
                    <Spacer size="zr sm">
                      <PlanningChallengeCard
                        title={challenge.title}
                        picture={challenge.picture}
                        emoji={challenge.emoji}
                        dimensions={challenge.dimensions}
                        target={challenge.target}
                        goals={challenge.goals}
                      />
                    </Spacer>
                  </BoardColumnItem>
                  <Spacer size="xsm" />
                </React.Fragment>
              ))}
            </>
          )}
          {isCoachingToolsEnabled && sprint.coachingTools.length > 0 && (
            <>
              <PlanningBoardItemsSeparator
                type="coaching tool"
                itemsCount={sprint.coachingTools.length}
                sprintState={sprint.state}
              />
              {sprint.coachingTools.map(
                ({ name, description, picture, id }) => (
                  <Spacer size="sm" key={id}>
                    <CoachingToolCard
                      name={name}
                      description={description}
                      url={picture?.url}
                      id={id}
                    >
                      <CoachingToolCardMenu toolId={id}>
                        <CoachingToolCardMenuCopyToSprint toolId={id} />
                        {sprint.state !== SPRINT_STATES.FINISHED && (
                          <>
                            <CoachingToolCardMenuMoveToSprint
                              sprintId={sprint.sprintId}
                              toolId={id}
                            />
                            <CoachingToolCardMenuRemoveButton
                              toolId={id}
                              sprintId={sprint.sprintId}
                            />
                          </>
                        )}
                      </CoachingToolCardMenu>
                    </CoachingToolCard>
                  </Spacer>
                )
              )}
            </>
          )}
        </BoardColumn>
      </BoardColumnWrapper>
    </PlanningSprintWrapper>
  );
};
