import { Box } from 'shared';

import { IChallenge } from 'features/challenge/config/types';
import {
  PlanningChallengeCard,
  PlanningBacklogMenu,
  useOpenEditBacklogChallengeDialog,
} from 'features/planning';

type TPlanningBacklogChallengeCard = {
  challenge: IChallenge;
};

export const PlanningBacklogChallengeCard = ({
  challenge,
}: TPlanningBacklogChallengeCard) => {
  const { title, picture, emoji, dimensions, goals, target } = challenge;
  const { openEditChallengeModal } = useOpenEditBacklogChallengeDialog({
    challenge,
  });

  return (
    <Box onClick={openEditChallengeModal}>
      <PlanningChallengeCard
        title={title}
        picture={picture}
        emoji={emoji}
        dimensions={dimensions}
        target={target}
        goals={goals}
      >
        <PlanningBacklogMenu challenge={challenge} />
      </PlanningChallengeCard>
    </Box>
  );
};
