import { Box } from 'shared';

import { IChallenge } from 'features/challenge/config/types';
import {
  PlanningChallengeCard,
  PlanningDraftSprintsItemMenu,
  IPlanningDraftSprint,
  useOpenEditDraftSprintChallengeDialog,
} from 'features/planning';

type TPlanningDraftSprintChallengeCard = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintChallengeCard = ({
  challenge,
  sprint,
}: TPlanningDraftSprintChallengeCard) => {
  const { title, picture, emoji, dimensions, goals, target } = challenge;

  const { openEditChallengeModal } = useOpenEditDraftSprintChallengeDialog({
    challenge,
    sprint,
  });

  return (
    <Box onClick={openEditChallengeModal}>
      <PlanningChallengeCard
        title={title}
        picture={picture}
        emoji={emoji}
        dimensions={dimensions}
        target={target}
        goals={goals}
      >
        <PlanningDraftSprintsItemMenu challenge={challenge} sprint={sprint} />
      </PlanningChallengeCard>
    </Box>
  );
};
