export const COACH_SEARCH_ENABLED = 'coachSearchEnabled';
export const SPRINT_DURATION = 'sprintDuration';
export const PLANNING_FEATURE_FLAG = 'sprintPlanning';
export const SPRINT_AUTOSAVE_FEATURE_FLAG = 'webSprintAutosave';
export const CHALLENGE_PROOFS_FEATURE_FLAG = 'challengeProofs';
export const SESSION_RECAP_NEW_EDITOR = 'newTextEditor';
export const COACH_INSIGHTS_FEATURE_FLAG = 'coachInsights';
export const CHAT_AUDIO_MESSAGES = 'chatAudioMessages';
export const CHAT_MESSAGES_SCHEDULING = 'chatMessagesScheduling';
export const COACHING_TOOLS_FEATURE_FLAG = 'coachingTools';
export const GOALS_DND = 'webGoalsDnd';
export const SESSION_RECAP_PREVIEW_FEATURE_FLAG = 'webSessionRecapPreview';
