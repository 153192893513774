import PropTypes from 'prop-types';

import Box from '../Box';
import { useTabPanelContext } from './context';

const TabPanel = ({ children, height = '100%' }) => {
  const tabPanelAttributes = useTabPanelContext();

  return (
    <Box
      role="tabpanel"
      {...tabPanelAttributes}
      style={{
        height,
      }}
    >
      {children}
    </Box>
  );
};

TabPanel.propTypes = {
  /**
   * Content of the tab panel.
   * This is shown when the tab panel is shown.
   * This is not shown when the tab panel is not shown.
   */
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
};

export default TabPanel;
