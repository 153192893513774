import { IChallenge } from 'features/challenge/config/types';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import {
  IPlanningDraftSprint,
  useEditPlanningDraftSprintChallengeMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';

type TUseOpenEditDraftSprintChallengeDialog = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const useOpenEditDraftSprintChallengeDialog = ({
  challenge,
  sprint,
}: TUseOpenEditDraftSprintChallengeDialog) => {
  const openChallengeModal = useOpenChallengeModal();
  const { sendPlanningChallengeEditedEvent } = usePlanningAnalyticsEvents();

  const { mutateAsync: onChallengeSubmit } =
    useEditPlanningDraftSprintChallengeMutation({
      sprint,
    });

  const openEditChallengeModal = () =>
    openChallengeModal({
      challenge,
      onChallengeSubmit: (challenge: IChallenge) => {
        sendPlanningChallengeEditedEvent();

        onChallengeSubmit(challenge);
      },
      title: 'Create Challenge',
      sprint,
    });

  return { openEditChallengeModal };
};
