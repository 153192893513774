import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import Box from 'shared_DEPRECATED/components/Box';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import {
  useAddPlanningDraftSprintMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';

import { Button, VARIANTS } from 'shared/components/Button';
import { Typography } from 'shared/components/Typography';

export const PlanningAddSprintButton = () => {
  const { mutateAsync: addSprint } = useAddPlanningDraftSprintMutation();
  const { sendPlanningSprintDraftAddedEvent } = usePlanningAnalyticsEvents();

  const handleAddSprint = async () => {
    const toastId = toast.loading('Adding draft sprint...', toastConfig);

    try {
      await addSprint();

      sendPlanningSprintDraftAddedEvent();

      toast.update(toastId, {
        render: 'Draft sprint added!',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    } catch (e) {
      toast.update(toastId, {
        render:
          "There's an error with adding draft sprint. Please try again later.",
        type: 'error',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    }
  };

  return (
    <Box
      style={{
        borderRadius: 'var(--border-radius16)',
        overflow: 'hidden',
      }}
    >
      <Button
        onClick={handleAddSprint}
        variant={VARIANTS.PRIMARY}
        leftIcon={
          <Icon
            name={iconNames.plus}
            width={13}
            height={13}
            strokeWidth={2}
            stroke="white"
          />
        }
      >
        <Spacers sizes={['md sm', 'sm sm sm zr']}>
          <Typography color="whiteTotal" fontWeight="medium">
            Add sprint draft
          </Typography>
        </Spacers>
      </Button>
    </Box>
  );
};
