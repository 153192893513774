import { Flex, Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import { PLANNING_SPRINT_BACKGROUND_STYLES_MAP } from 'features/planning';
import { SprintStateValues } from 'features/sprint/config/types';

import { Typography } from 'shared/components/Typography';

type TPlanningBoardItemsSeparator = {
  navigation?: ReactNode;
  sprintState?: SprintStateValues;
  itemsCount: number;
  type: 'challenge' | 'coaching tool';
};

export const PlanningBoardItemsSeparator = ({
  navigation,
  sprintState,
  type,
  itemsCount,
}: TPlanningBoardItemsSeparator) => {
  const title = getPlanningBoardItemsWrapperTitle(type, itemsCount);

  return (
    <Flex flexDirection="column" width="100%" alignItems="stretch">
      <Box
        style={{
          backgroundColor: sprintState
            ? PLANNING_SPRINT_BACKGROUND_STYLES_MAP[sprintState]
            : 'var(--bgPrimary)',
          width: '100%',
        }}
      >
        <Box bg="var(--bgCompSecondaryBig)" p="0 0.25rem">
          <Flex
            width="100%"
            height="2rem"
            alignItems="center"
            justifyContent="space-between"
          >
            <Spacer size="sm">
              {!!itemsCount && (
                <Typography type="small" color="gray">
                  {title}
                </Typography>
              )}
            </Spacer>
            {navigation}
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

const getPlanningBoardItemsWrapperTitle = (
  type: string,
  itemsCount: number
) => {
  const pluralEnding = itemsCount > 1 ? 's' : '';

  return `${itemsCount} ${type}${pluralEnding}`;
};
