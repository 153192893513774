import { logError } from 'lib/sentry/logError';

import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  useExploreChallengesDialog,
  useAddChallengesToBacklogMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { useSprints } from 'features/sprint/hooks';

export const useBacklogAddLibraryChallenge = () => {
  const { mutateAsync: addChallenges } = useAddChallengesToBacklogMutation();
  const { data: sprints } = useSprints();
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();

  const onSubmit = async (
    newChallenges:
      | (IRepeatedChallenge | INonRepeatedChallenge)[]
      | (INonRepeatedChallenge | IRepeatedChallenge)
  ) => {
    try {
      const challenges = Array.isArray(newChallenges)
        ? newChallenges
        : [newChallenges];

      await addChallenges(challenges);

      sendPlanningChallengeAddedEvent('backlog');
    } catch (error) {
      logError(error);
    }
  };

  const openExploreChallengesModal = useExploreChallengesDialog({
    sprint: sprints[0],
    onSubmit,
  });

  return openExploreChallengesModal;
};
