import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { getNoteRepliesQueryKey } from 'api/utils';
import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';
import { Reactions } from 'shared_DEPRECATED/components/Reactions';
import { DEFAULT_REACTIONS } from 'shared_DEPRECATED/components/Reactions/config';
import { ReactionType } from 'shared_DEPRECATED/config/propTypes';

import { useReplyReactionsMutation } from 'features/challengeNotes/hooks/mutation/useReplyReactions';
import useReactionsData from 'features/challengeNotes/hooks/useReactionsData';
import { updateReplyReactions } from 'features/challengeNotes/utils';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesReplyReactionsPropTypes = {
  noteId: PropTypes.string.isRequired,
  replyId: PropTypes.string.isRequired,
  reactions: PropTypes.objectOf(PropTypes.arrayOf(ReactionType)),
  noteOwnerId: PropTypes.string.isRequired,
};

export const ChallengeNotesReplyReactions = ({
  noteId,
  replyId,
  reactions,
  noteOwnerId,
}) => {
  const { user: currentUser } = useSidebarContext();
  const preparedReactions = useReactionsData(reactions, currentUser.email);

  const { sendNoteReplyReactionAddedAnalyticsEvent } = useNotesAnalytics();

  const noteRepliesQueryKey = getNoteRepliesQueryKey(noteId);

  const queryClient = useQueryClient();

  const onReplyReactionsError = () => {
    queryClient.invalidateQueries(noteRepliesQueryKey);
  };

  const onReplyReactionsMutate = ({ emoji, type }) => {
    queryClient.setQueryData(noteRepliesQueryKey, (old) => {
      const updatedReplies = updateReplyReactions({
        replies: old.replies,
        replyId,
        emoji,
        type,
        userInfo: currentUser,
      });

      return {
        replies: updatedReplies,
        note: old.note,
      };
    });
  };

  const reactionsMutation = useReplyReactionsMutation({
    noteId,
    replyId,
    noteOwnerId,
    onMutate: onReplyReactionsMutate,
    onError: onReplyReactionsError,
    onSuccess: sendNoteReplyReactionAddedAnalyticsEvent,
  });

  return (
    <Reactions
      reactions={preparedReactions}
      onEmojiSelect={({ emoji, type }) => {
        reactionsMutation.mutate({ emoji, type });
      }}
      emojis={DEFAULT_REACTIONS}
      options={{
        perLine: 7,
        skinTonePosition: 'none',
        theme: 'light',
        previewPosition: 'none',
        navPosition: 'none',
        categories: [
          'people',
          'nature',
          'foods',
          'activity',
          'places',
          'objects',
          'symbols',
          'flags',
        ],
      }}
    />
  );
};

ChallengeNotesReplyReactions.propTypes = ChallengeNotesReplyReactionsPropTypes;
