import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { IChallenge } from 'features/challenge/config/types';
import { useOpenEditDraftSprintChallengeDialog } from 'features/planning';
import { IPlanningDraftSprint } from 'features/planning/types';

import { Typography } from 'shared/components/Typography';

type TPlanningDraftSprintMenuEditButton = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintMenuEditButton = ({
  challenge,
  sprint,
}: TPlanningDraftSprintMenuEditButton) => {
  const { openEditChallengeModal } = useOpenEditDraftSprintChallengeDialog({
    challenge,
    sprint,
  });

  return (
    <OptionsMenuButton onClick={openEditChallengeModal}>
      <Typography color="black">Edit</Typography>
    </OptionsMenuButton>
  );
};
