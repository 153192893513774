import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { v4 as uuidv4 } from 'uuid';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useAddChallengesToBacklogMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  const { userId } = useCurrentModeUser();

  return useMutation(
    async (challenges: TChallenges) =>
      await request({
        url: `/api/web/sprint-planning/backlog/challenges/create`,
        body: { challenges, userId },
      }),
    {
      onMutate: async (challenges: TChallenges) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.challenges],
        });

        const challengesWithTemporaryChallengeIds = [...challenges].map(
          (challenge) => ({ ...challenge, challengeId: uuidv4() })
        );

        const previousChallengesValue = queryClient.getQueryData<{
          items: TChallenges;
        }>([queryKeys.challenges]);

        if (previousChallengesValue) {
          queryClient.setQueryData<{ items: TChallenges }>(
            [queryKeys.challenges],
            (oldVal) => ({
              ...oldVal,
              items: [...challengesWithTemporaryChallengeIds, ...oldVal!.items],
            })
          );
        }

        return { previousChallengesValue };
      },
      onError: (
        _: Error,
        __: any,
        context?: { previousChallengesValue?: { items: TChallenges } }
      ) => {
        if (context?.previousChallengesValue) {
          queryClient.setQueryData(
            [queryKeys.challenges],
            context.previousChallengesValue
          );
        }
      },
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.challenges],
        });
      },
    }
  );
};

type TChallenges = (IRepeatedChallenge | INonRepeatedChallenge)[];
