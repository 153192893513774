import PropTypes from 'prop-types';

import { MenuItem } from 'shared';

import { NOTE_STATUS, NOTE_TYPES } from 'features/challengeNotes/config';
import { useNoteStatusMutation } from 'features/challengeNotes/hooks';

const MenuReadStatusButtonPropTypes = {
  noteId: PropTypes.string.isRequired,
  noteType: PropTypes.oneOf(Object.values(NOTE_TYPES)).isRequired,
  userEmail: PropTypes.string.isRequired,
  noteReadStatus: PropTypes.oneOf(Object.values(NOTE_STATUS)).isRequired,
  dataTestid: PropTypes.string,
};
export const MenuReadStatusButton = ({
  noteId,
  noteType,
  noteReadStatus,
  userEmail,
  dataTestid = null,
}) => {
  const readStatusMutation = useNoteStatusMutation({
    noteId,
    userEmail,
  });

  const handleReadStatusMutation = () =>
    readStatusMutation.mutate({
      currentStatus: noteReadStatus,
      noteType,
    });

  return (
    <MenuItem onClick={handleReadStatusMutation} data-testid={dataTestid}>
      {noteReadStatus === NOTE_STATUS.UNREAD
        ? 'Mark as read'
        : 'Mark as unread'}
    </MenuItem>
  );
};

MenuReadStatusButton.propTypes = MenuReadStatusButtonPropTypes;
