import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import { IChallenge } from 'features/challenge/config/types';
import { IPlanningDraftSprint } from 'features/planning';

type TUseEditPlanningDraftSprintChallengeMutation = {
  sprint: IPlanningDraftSprint;
};

export const useEditPlanningDraftSprintChallengeMutation = ({
  sprint,
}: TUseEditPlanningDraftSprintChallengeMutation) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    //@ts-ignore
    (challenge: IChallenge) =>
      request({
        url: `/api/web/sprints/${sprint.sprintId}/challenges/edit`,
        body: { challenges: [challenge] },
      }),
    {
      onMutate: async (updatedChallenge: IChallenge) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              const updatedSprint = {
                ...sprint,
                challenges: updateArrayById({
                  array: sprint.challenges,
                  updatedItem: { ...updatedChallenge, goals: [] },
                  idProperty: 'challengeId',
                }),
              };

              return {
                ...oldVal,
                items: updateArrayById({
                  array: oldVal!.items,
                  updatedItem: updatedSprint,
                  idProperty: 'sprintId',
                }),
              };
            }
          );
        }

        return { previousDraftSprintsValue };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
        queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
      onError: (
        err: Error,
        _: any,
        context: { previousDraftSprintsValue?: IPlanningDraftSprint[] }
      ) => {
        queryClient.setQueryData(
          [queryKeys.draftSprints],
          context?.previousDraftSprintsValue
        );
      },
    }
  );
};
