import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCreateChallengeModal } from 'features/challenge/hooks/useCreateChallengeModal';
import {
  useAddChallengesToBacklogMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { useSprints } from 'features/sprint/hooks';

export const useAddBacklogChallenge = () => {
  const { data: sprints } = useSprints();
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();
  const { mutateAsync: addChallenge } = useAddChallengesToBacklogMutation();

  const openAddChallengeModal = useCreateChallengeModal({
    mutateFunc: async (challenge) => {
      await addChallenge([
        challenge as IRepeatedChallenge | INonRepeatedChallenge,
      ]);

      sendPlanningChallengeAddedEvent('backlog');
    },
    sprint: sprints[0],
  });

  return openAddChallengeModal;
};
