import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCreateChallengeModal } from 'features/challenge/hooks/useCreateChallengeModal';
import {
  IPlanningDraftSprint,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { useAddChallengesToSprintMutation } from 'features/sprint/hooks/mutation/useAddChallengesToSprint';

type TUseAddDraftSprintChallenge = {
  sprint: IPlanningDraftSprint;
};

export const useAddDraftSprintChallenge = ({
  sprint,
}: TUseAddDraftSprintChallenge) => {
  const queryClient = useQueryClient();

  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();

  const { mutateAsync: addChallenge } = useAddChallengesToSprintMutation({
    sprintId: sprint.sprintId,
    options: {
      onMutate: async (
        challenges: (IRepeatedChallenge | INonRepeatedChallenge)[]
      ) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => ({
              ...oldVal,
              items: updateArrayById({
                array: oldVal!.items,
                updatedItem: {
                  ...sprint,
                  challenges: [
                    ...sprint.challenges,
                    { ...challenges[0], goals: [] },
                  ],
                },
                idProperty: 'sprintId',
              }),
            })
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (
        _: Error,
        __: any,
        context:
          | {
              previousDraftSprintsValue?: {
                items: IPlanningDraftSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.draftSprints],
        });
      },
    },
  });

  const openAddChallengeModal = useCreateChallengeModal({
    mutateFunc: async (challenge) => {
      await addChallenge([
        challenge as IRepeatedChallenge | INonRepeatedChallenge,
      ]);

      sendPlanningChallengeAddedEvent('sprintDraft');
    },
    sprint: sprint,
  });

  return openAddChallengeModal;
};
