import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';
import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  ChallengeNotesCard,
  ChallengeNotesCardReactions,
  ChallengeNotesCardRecap,
  ChallengeNotesCardReplies,
} from 'features/challengeNotes/components/Card';
import { NOTE_TYPES } from 'features/challengeNotes/config';
import { ChallengeNotesType } from 'features/challengeNotes/config/propTypes';
import { useChallengeNotesContext } from 'features/challengeNotes/context';
import { useChallengeNotesQueryKey } from 'features/challengeNotes/hooks';
import { updateReactions } from 'features/challengeNotes/utils';
import { groupBySprintWeek } from 'features/sprint/utils';

import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

const noteComponentsMap = {
  [NOTE_TYPES.CHALLENGE_NOTE]: ChallengeNotesCard,
  [NOTE_TYPES.SESSION_RECAP_NOTE]: ChallengeNotesCardRecap,
};

const ChallengeNotesContentPropTypes = {
  notes: ChallengeNotesType,
  children: PropTypes.node,
  noteType: PropTypes.oneOf(Object.values(NOTE_TYPES)),
};

export const ChallengeNotesContent = ({ noteType }) => {
  const { notes } = useChallengeNotesContext();
  const { sprint, user } = useSidebarContext();
  const notesSortedByChallengeStatusDate = [...notes].sort((note1, note2) =>
    dateUtils.sortingDiff(note2.data.statusDate, note1.data.statusDate)
  );
  const sections = groupBySprintWeek(notesSortedByChallengeStatusDate, sprint);
  const queryClient = useQueryClient();
  const challengeNotesQueryKey = useChallengeNotesQueryKey();

  const { sendNoteReactionAddedAnalyticsEvent } = useNotesAnalytics();

  const NoteComponent = noteComponentsMap[noteType];

  const onReactionsError = (_, __, context) => {
    queryClient.setQueryData(
      challengeNotesQueryKey,
      context.previousChallengeNotes
    );
  };

  const onReactionsMutate =
    (note) =>
    ({ emoji, type }) => {
      const previousChallengeNotes = queryClient.getQueryData(
        challengeNotesQueryKey
      );

      queryClient.setQueryData(challengeNotesQueryKey, (old) =>
        old.map((noteItem) => {
          if (noteItem.noteId === note.noteId) {
            return {
              ...noteItem,
              reactions: updateReactions({
                reactions: noteItem.reactions,
                emoji,
                type,
                userInfo: user,
              }),
            };
          }
          return noteItem;
        })
      );

      return { previousChallengeNotes, type };
    };

  return Object.entries(sections).map(([sectionTitle, sectionNotes], index) => (
    <Box
      as="section"
      title={`${sectionTitle} challenge notes`}
      key={sectionTitle}
    >
      <Spacer size={`${index === 0 ? 'zr' : 'lg'} lg zr`}>
        <Typography as="h4" color="gray" fontWeight="semiBold" type="small">
          {sectionTitle}
        </Typography>
      </Spacer>
      {sectionNotes.map((note) => (
        <NoteComponent key={note.noteId} note={note}>
          <ChallengeNotesCardReplies
            noteId={note.noteId}
            repliesCount={note.repliesCount}
            userEmail={note.userEmail}
          />
          <ChallengeNotesCardReactions
            noteStatus={note.status}
            noteType={note.noteType}
            noteId={note.noteId}
            reactions={note.reactions}
            userEmail={note.userEmail}
            onError={onReactionsError}
            onMutate={onReactionsMutate(note)}
            onSuccess={sendNoteReactionAddedAnalyticsEvent}
          />
        </NoteComponent>
      ))}
    </Box>
  ));
};

ChallengeNotesContent.propTypes = ChallengeNotesContentPropTypes;
