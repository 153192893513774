import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalAddFormNameSection,
  GoalAddFormTimelineSection,
  GoalAddFormDeadlineSection,
  GoalAddFormDimensionsSection,
  GoalAddFormNoteSection,
  GoalAddDialogHeader,
  GoalAddDialogFooter,
  GOAL_DEFAULT_VALUE,
  IGoal,
} from 'features/goal';

import { ModalBody, ModalHeader, ModalFooter } from 'shared/components/Modal';

type TGoalAddDialog = {
  onSubmit: (goal: IGoal) => void;
  defaultValues: IGoal;
  title: string;
};

const GoalAddDialog = ({
  onSubmit,
  defaultValues = GOAL_DEFAULT_VALUE,
  title,
}: TGoalAddDialog) => {
  const methods = useForm<IGoal>({
    defaultValues,
  });

  const onSubmitButtonClick = methods.handleSubmit((goal: IGoal) =>
    onSubmit(goal)
  );

  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, [methods]);

  return (
    <>
      <ModalHeader>
        <GoalAddDialogHeader title={title} />
      </ModalHeader>
      <FormProvider {...methods}>
        <ModalBody>
          <Spacer size="sm" />
          <GoalAddFormNameSection />
          <GoalAddFormTimelineSection />
          <GoalAddFormDeadlineSection />
          <GoalAddFormDimensionsSection />
          <GoalAddFormNoteSection />
        </ModalBody>
        <ModalFooter>
          <GoalAddDialogFooter onSubmit={onSubmitButtonClick} />
        </ModalFooter>
      </FormProvider>
    </>
  );
};

export default GoalAddDialog;
