import { IChallenge } from 'features/challenge/config/types';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import { useEditCustomChallenge } from 'features/challenge/hooks/useEditCustomChallenge';
import { usePlanningAnalyticsEvents } from 'features/planning';

type TUseOpenEditBacklogChallengeDialog = {
  challenge: IChallenge;
};

export const useOpenEditBacklogChallengeDialog = ({
  challenge,
}: TUseOpenEditBacklogChallengeDialog) => {
  const { sendPlanningChallengeEditedEvent } = usePlanningAnalyticsEvents();
  const { editCustomChallenge: onChallengeSubmit } = useEditCustomChallenge();

  const openChallengeModal = useOpenChallengeModal();

  const openEditChallengeModal = () =>
    openChallengeModal({
      challenge,
      onChallengeSubmit: (challenge: IChallenge) => {
        sendPlanningChallengeEditedEvent();

        onChallengeSubmit(challenge);
      },
      title: 'Create Challenge',
      sprint: {
        //The challenge backlog is not tied to any specific sprint and does not impose limits on the number of challenges.
        availableChallengeFields: { challenges: 100, oneTimeChallenges: 100 },
      },
    });

  return { openEditChallengeModal };
};
